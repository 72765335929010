import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import Container from "src/components/Container";

import { Helmet } from "react-helmet";
import Footer from "./Footer";
import TopBar from "./TopBar";

function SeekingHelpBar() {
  return (
    <Box
      py={1}
      sx={{
        backgroundColor: "warningInfo.light",
        position: { sm: "sticky" },
        top: { sm: 64 },
        boxShadow: (theme) => ({
          sm: theme.shadows[1],
        }),
        zIndex: 1,
      }}
    >
      <Container style={{ overflow: "auto" }}>
        <Typography style={{ float: "left" }}>
          Seeking emergency housing?{" "}
          <strong>
            <Link href="tel:1-510-532-3211">Call (510)&nbsp;532-3211</Link>
          </strong>
        </Typography>
        <Typography style={{ float: "right" }}>
          <strong>
            <Link to="/veteran-services">
              Are you a Veteran in need of housing assistance?
            </Link>
          </strong>
        </Typography>
      </Container>
    </Box>
  );
}

export default function Layout({ children, footerChildren }) {
  return (
    <Box>
      <Helmet
        titleTemplate="%s - East Oakland Community Project"
        defaultTitle="East Oakland Community Project"
      ></Helmet>
      <TopBar />
      <SeekingHelpBar />
      <Box
        sx={{
          maxWidth: 1200,
          margin: { lg: "2rem auto" },
          minHeight: "45vh",
        }}
      >
        {children}
      </Box>
      <Footer>{footerChildren}</Footer>
    </Box>
  );
}
