import MuiContainer from "@mui/material/Container";
import * as React from "react";

export default function Container({ sx = {}, ...props }) {
  return (
    <MuiContainer
      sx={{ paddingLeft: { lg: 6 }, paddingRight: { lg: 6 }, ...sx }}
      {...props}
    />
  );
}
