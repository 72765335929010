import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Container from "src/components/Container";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import NAV_STRUCTURE from "src/structure";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import logo from "src/images/logo.svg";
import NavItems from "./NavItems";
import { styled } from "@mui/material/styles";
import useIsActiveLink from "src/hooks/useIsActiveLink";

const MenuTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[12],
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TopBar() {
  const isLinkActive = useIsActiveLink();
  const [navOpen, setNavOpen] = React.useState(false);
  const close = () => setNavOpen(false);
  return (
    <>
      <AppBar color="default">
        <Container>
          <Toolbar sx={{ padding: "0 !important" }}>
            <Box
              sx={{ ml: "-8px" }}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              component={Link}
              to="/"
            >
              <img src={logo} alt="EOCP" />
            </Box>
            <Button
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              endIcon={<MenuIcon />}
              sx={{ ml: "auto", display: { sm: "none" } }}
              onClick={() => setNavOpen(true)}
            >
              Menu
            </Button>
            <Box
              sx={{ display: { xs: "none", sm: "block" }, textAlign: "right" }}
              ml="auto"
            >
              {NAV_STRUCTURE.map(({ title, to, parent, asNav }) => {
                if (parent) {
                  // don't show sub-pages
                  return null;
                }
                const isActive = isLinkActive(to);
                const { menu } = asNav || {};
                const link = (
                  <Link
                    key={title}
                    to={to}
                    sx={{
                      fontWeight: (theme) => theme.typography.fontWeightBold,
                      color: (theme) => theme.palette.secondary.main,
                      background: (theme) =>
                        isActive
                          ? theme.palette.green[100]
                          : theme.palette.transparent,
                      "&:hover": {
                        background: (theme) =>
                          isActive
                            ? theme.palette.green[100]
                            : theme.palette.green[50],
                      },
                      whiteSpace: "nowrap",
                      display: "inline-block",
                      textDecoration: "none",
                      px: 1,
                      ml: 0.5,
                      my: 0.25,
                      borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                    }}
                  >
                    {title}
                  </Link>
                );

                if (menu) {
                  return (
                    <MenuTooltip
                      title={menu.map((menuTo) => {
                        const isMenuItemActive = isLinkActive(menuTo);
                        const menuItem = NAV_STRUCTURE.find(
                          (x) => x.to === menuTo
                        );
                        if (menuItem) {
                          return (
                            <Box key={menuTo}>
                              <Link
                                to={menuItem.to}
                                sx={{
                                  display: "inline-block",
                                  px: 1,
                                  py: 0.25,
                                  my: 0.25,
                                  color: (theme) =>
                                    theme.palette.secondary.main,
                                  background: (theme) =>
                                    isMenuItemActive
                                      ? theme.palette.green[100]
                                      : theme.palette.transparent,
                                  "&:hover": {
                                    background: (theme) =>
                                      isMenuItemActive
                                        ? theme.palette.green[100]
                                        : theme.palette.green[50],
                                  },
                                  borderRadius: (theme) =>
                                    `${theme.shape.borderRadius}px`,
                                  textDecoration: "none",
                                }}
                              >
                                <Typography>{menuItem.title}</Typography>
                              </Link>
                            </Box>
                          );
                        }
                        return null;
                      })}
                    >
                      {link}
                    </MenuTooltip>
                  );
                }
                return link;
              })}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {/* empty Toolbar as spacer */}
      <Toolbar />
      <Drawer anchor={"right"} open={navOpen} onClose={close}>
        <Box px={2} py={1} sx={{ maxWidth: "80vw" }}>
          <NavItems color="secondary" onClick={() => setNavOpen(false)} />
        </Box>
      </Drawer>
    </>
  );
}
