import Box from "@mui/material/Box";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import NAV_STRUCTURE from "src/structure";
import useIsActiveLink from "src/hooks/useIsActiveLink";

export default function NavItems({ onClick, ...props }) {
  const isLinkActive = useIsActiveLink();
  return NAV_STRUCTURE.map(({ title, to, parent }) => {
    const isActive = isLinkActive(to);

    return (
      <Box key={title} my={1}>
        <Link
          onClick={onClick}
          to={to}
          {...props}
          sx={{
            fontWeight: (theme) =>
              parent ? "inherit" : theme.typography.fontWeightBold,
            fontSize: parent ? 13 : "inherit",
            background: (theme) =>
              isActive
                ? theme.palette.mode === "dark"
                  ? "rgba(255,255,255,0.15)"
                  : theme.palette.green[50]
                : "transparent",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
            px: 1,
            py: 0.5,
            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          }}
        >
          {title}
        </Link>
      </Box>
    );
  });
}
