import * as React from "react";
import Box from "@mui/material/Box";

import { darkTheme } from "src/gatsby-theme-material-ui-top-layout/theme";
import { ThemeProvider } from "@mui/material/styles";

export default function InvertBox({ children, ...props }) {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box {...props}>{children}</Box>
    </ThemeProvider>
  );
}
