import * as React from "react";
import { useLocation } from "@reach/router";
import NAV_STRUCTURE from "src/structure";

// returns function that determines whether a link should be considered "active"
export default function useIsActiveLink() {
  const location = useLocation();
  let pathname = location.pathname;
  if (pathname.length > 1 && pathname.endsWith("/")) {
    // strip trailing slash
    pathname = pathname.substr(0, pathname.length - 1);
  }
  const activeNav = NAV_STRUCTURE.find((x) => x.to === pathname);

  return React.useCallback(
    (to) => {
      if (!to) {
        return false;
      }

      // check for direct match
      if (pathname === to) {
        return true;
      }
      // check for hardcoded parent
      if (activeNav?.parent === to) {
        return true;
      }
      // check for pathname parent match; only works for top level folders
      // currently applies to community-stories
      if (to.length > 1 && pathname.indexOf(to + "/") === 0) {
        return true;
      }
      return false;
    },
    [pathname, activeNav]
  );
}
