import { DONATE_URL } from "src/constants";
import whatWeDoImage from "src/images/nav/what-we-do.jpg";
import whyDoWeDoThisWorkImage from "src/images/nav/why-do-we-do-this-work.jpg";
import residentialServicesImage from "src/images/nav/residential-services.jpg";
import communityBasedServicesImage from "src/images/nav/community-based-services.jpg";
import vetsImage from "src/images/nav/vets.png";
const NAV_STRUCTURE = [
  { title: "Home", to: "/" },
  { title: "About Us", to: "/about-us" },
  {
    title: "What We Do",
    to: "/what-we-do",
    asNav: {
      img: whatWeDoImage,
      menu: [
        "/why-we-do-this-work",
        "/veteran-services",
        "/residential-services",
        "/community-based-services",
      ],
    },
  },
  {
    title: "Why We Do This Work",
    to: "/why-we-do-this-work",
    parent: "/what-we-do",
    asNav: {
      img: whyDoWeDoThisWorkImage,
    },
  },
  {
    title: "Veteran Services",
    to: "/veteran-services",
    parent: "/what-we-do",
    asNav: {
      img: vetsImage,
    },
  },
  {
    title: "Residential Services",
    to: "/residential-services",
    parent: "/what-we-do",
    asNav: {
      img: residentialServicesImage,
    },
  },
  {
    title: "Community-based Services",
    to: "/community-based-services",
    parent: "/what-we-do",
    asNav: {
      img: communityBasedServicesImage,
    },
  },
  {
    title: "Work With Us",
    to: "/work-with-us",
  },
  { title: "Community Stories", to: "/community-stories" },
  { title: "Support EOCP", to: "/donate" },

  { title: "Donate Now", to: DONATE_URL },
];

export function getNavEntryByTo(to) {
  // get first entry that matches. Using this function allows future consideration of a "primary" entry or similar
  return NAV_STRUCTURE.find((x) => x.to === to);
}

export default NAV_STRUCTURE;
