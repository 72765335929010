import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import Container from "src/components/Container";
import InvertBox from "src/components/InvertBox";
import logo from "src/images/logo.svg";
import EmailForm from "./EmailForm";
import NavItems from "./NavItems";

export default function Footer({ children }) {
  return (
    <>
      <Container>
        <Box
          component="img"
          src={logo}
          alt="EOCP"
          pt={{ xs: 5, md: 10 }}
          sx={{
            display: "block",
            width: "36vw",
            maxWidth: 300,
            opacity: 0.3,
            transform: "translateX(-13%)",
          }}
        />
      </Container>
      {children}
      <InvertBox sx={{ backgroundColor: "background.default" }} py={5}>
        <Container>
          <EmailForm />
          <Divider sx={{ my: 3 }} />
          <NavItems />
          <Box mt={3}>
            <IconButton component="a" href="https://www.instagram.com/eocpnet/">
              <InstagramIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.facebook.com/profile.php?id=100088202749373"
            >
              <FacebookIcon />
            </IconButton>
          </Box>
          <Typography
            color="textSecondary"
            pt={4}
            pb={3}
            variant="caption"
            display="block"
          >
            7515 International Boulevard
            <br />
            Oakland, CA 94621
            <br />
            <Link href="tel:1-510-532-3211">(510)&nbsp;532-3211</Link>
            <br />
            &copy; {new Date().getFullYear()} East Oakland Community Project
          </Typography>
        </Container>
      </InvertBox>
    </>
  );
}
