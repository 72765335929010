import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Overline from "src/components/Overline";
import addToMailchimp from "gatsby-plugin-mailchimp";

export default function EmailForm({ hideTitle = false }) {
  const fnameRef = React.useRef();
  const lnameRef = React.useRef();
  const emailRef = React.useRef();
  const [diagnostics, setDiagnostics] = React.useState();

  return (
    <form
      onSubmit={async (e) => {
        setDiagnostics({});
        e.preventDefault();
        try {
          const fields = {
            FNAME: fnameRef.current?.value,
            LNAME: lnameRef.current?.value,
          };
          const email = emailRef.current?.value;

          // check fname, lname
          const localDiagnostics = {};
          Object.keys(fields).forEach((field) => {
            if (!`${fields[field]}`.trim()) {
              localDiagnostics[field] = {
                result: "error",
                msg: "Field required",
              };
            }
          });
          if (Object.keys(localDiagnostics).length) {
            setDiagnostics(localDiagnostics);
            return;
          }

          const result = await addToMailchimp(email, fields);

          if (result.msg.includes("already subscribed to list")) {
            setDiagnostics({
              email: { msg: "You're already subscribed, thanks!" },
            });
          } else {
            setDiagnostics({
              email: result,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {!hideTitle && <Overline>Sign up for our newsletter</Overline>}
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <TextField
          inputRef={fnameRef}
          label="First Name"
          sx={{ mr: 1, mb: 1, width: { sm: "13em", xs: "100%" } }}
          size="small"
          error={diagnostics?.FNAME?.result === "error"}
          helperText={diagnostics?.FNAME?.msg}
        />
        <TextField
          inputRef={lnameRef}
          label="Last Name"
          sx={{ mr: 1, mb: 1, width: { sm: "13em", xs: "100%" } }}
          size="small"
          error={diagnostics?.LNAME?.result === "error"}
          helperText={diagnostics?.LNAME?.msg}
        />
        <TextField
          inputRef={emailRef}
          label="Email"
          type="email"
          sx={{ mr: 1, mb: 1, width: { sm: "20em", xs: "100%" } }}
          size="small"
          error={diagnostics?.email?.result === "error"}
          helperText={diagnostics?.email?.msg}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ height: "40px" }}
        >
          Subscribe
        </Button>
      </Box>
    </form>
  );
}
